@import '../global/variables.less';

/* Breadcrumb Styles */
.ant-breadcrumb {
  .ant-breadcrumb-link {
    a {
      color: @primary-color;
    }
  }
}
