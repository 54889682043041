.ql-editor {
  & .ql-picker.ql-font {
    .ql-picker-item {
      font-size: 0;

      &:before {
        content: attr(data-value) !important;
        font-size: 14px;
      }
    }
  }

  & .ql-picker.ql-font {
    .ql-active {
      &:before {
        content: attr(data-value) !important;
        font-size: 14px;
      }
    }
  }

  & .ql-picker.ql-font .ql-picker-label[data-value='SourceSansPro']::before,
  & .ql-picker.ql-font .ql-picker-item[data-value='SourceSansPro']::before {
    font-family: 'Source Sans Pro', Source Sans Pro;
    content: 'Source Sans Pro' !important;
  }

  & .ql-picker.ql-font .ql-picker-label[data-value='Arial']::before,
  & .ql-picker.ql-font .ql-picker-item[data-value='Arial']::before {
    font-family: 'Arial', Arial;
    content: 'Arial' !important;
  }

  .ql-snow .ql-picker.ql-font .ql-picker-label[data-value='calibri']::before,
  .ql-snow .ql-picker.ql-font .ql-picker-item[data-value='calibri']::before {
    font-family: 'Calibri', sans-serif;
    content: 'Calibri', Calibri;
  }

  & .ql-picker.ql-font .ql-picker-label[data-value='courier']::before,
  & .ql-picker.ql-font .ql-picker-item[data-value='courier']::before {
    font-family: 'courier', courier;
    content: 'courier' !important;
  }

  & .ql-picker.ql-font .ql-picker-label[data-value='Georgia']::before,
  & .ql-picker.ql-font .ql-picker-item[data-value='Georgia']::before {
    font-family: 'Georgia', Georgia;
    content: 'Georgia' !important;
  }

  & .ql-picker.ql-font .ql-picker-label[data-value='lucida']::before,
  & .ql-picker.ql-font .ql-picker-item[data-value='lucida']::before {
    font-family: 'Lucida Sans Unicode', lucida;
    content: 'Lucida Sans' !important;
  }

  & .ql-picker.ql-font .ql-picker-label[data-value='Tahoma']::before,
  & .ql-picker.ql-font .ql-picker-item[data-value='Tahoma']::before {
    font-family: 'Tahoma', Tahoma;
    content: 'Tahoma' !important;
  }

  & .ql-picker.ql-font .ql-picker-label[data-value='Times']::before,
  & .ql-picker.ql-font .ql-picker-item[data-value='Times']::before {
    font-family: 'Times New Roman', Times;
    content: 'Times New Roman' !important;
  }

  .ql-snow .ql-picker.ql-font .ql-picker-label[data-value='trebuchet']::before,
  .ql-snow .ql-picker.ql-font .ql-picker-item[data-value='trebuchet']::before {
    font-family: 'Trebuchet MS', Trebuchet MS;
    content: 'Trebuchet MS';
  }

  .ql-snow .ql-picker.ql-font .ql-picker-label[data-value='verdana']::before,
  .ql-snow .ql-picker.ql-font .ql-picker-item[data-value='verdana']::before {
    font-family: 'Verdana', Verdana;
    content: 'Verdana';
  }

  & .ql-picker.ql-font .ql-picker-label[data-value='Montserrat']::before,
  & .ql-picker.ql-font .ql-picker-item[data-value='Montserrat']::before {
    font-family: 'Montserrat', Montserrat;
    content: 'Montserrat' !important;
  }

  /* Set content font-families */

  & .ql-font-Source-Sans-Pro {
    font-family: 'Source Sans Pro';
  }

  &.ql-font-arial {
    font-family: 'Arial';
  }

  .ql-font-calibri {
    font-family: 'Calibri', sans-serif;
  }

  & .ql-font-courier {
    font-family: 'courier';
  }

  & .ql-font-Georgia {
    font-family: 'Georgia';
  }

  & .ql-font-lucida {
    font-family: 'Lucida Sans Unicode', sans-serif;
  }

  & .ql-font-Tahoma {
    font-family: 'Tahoma';
  }

  & .ql-font-Times {
    font-family: 'Times';
  }

  & .ql-font-trebuchet {
    font-family: 'Trebuchet Ms', sans-serif;
  }

  & .ql-font-verdana {
    font-family: 'Verdana', sans-serif;
  }

  & .ql-font-Montserrat {
    font-family: 'Montserrat';
  }

  .ql-snow .ql-picker.ql-size .ql-picker-item[data-value='16px']::before {
    content: 'Small';
    font-size: 16px !important;
  }

  .ql-snow .ql-picker.ql-size .ql-picker-item[data-value='20px']::before {
    content: 'Normal';
    font-size: 20px !important;
  }

  .ql-snow .ql-picker.ql-size .ql-picker-item[data-value='38px']::before {
    content: 'Large';
    font-size: 38px !important;
  }

  .ql-snow .ql-picker.ql-size .ql-picker-item[data-value='58px']::before {
    content: 'Huge';
    font-size: 58px !important;
  }
}
