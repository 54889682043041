/* Collapse Styles */

.ant-collapse {
  & .ant-collapse-item {
    &.dashboard-collapse-panel {
      & .ant-collapse-header {
        white-space: pre;
        padding: 0;
        align-items: center;
      }

      & .ant-collapse-content {
        & .ant-collapse-content-box {
          padding: 12px 0px 0px 42px;
        }
      }
    }
  }
}
