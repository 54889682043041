/*Input Styles*/
.ant-input-search {
  margin-bottom: 16px;

  & > .ant-input-suffix > .ant-input-search-button {
    margin-right: 0;
  }
}

.ant-input-group {
  & > [class*='col-'] {
    padding-left: 0;
    float: left !important;
  }
}

.ant-transfer-list {
  margin-bottom: 10px;
}

.ant-input-number {
  margin-right: 10px;
}

.@{class-prefix}-form-group {
  margin-bottom: 10px;
}

// Search Bar Style
.@{class-prefix}-search-bar {
  position: relative;

  & .@{class-prefix}-form-group {
    width: 100%;
    height: 100%;
    margin-bottom: 0;
  }

  & input[type='search'] {
    padding-right: 35px;
    background: fade(@grey-2, 10%);

    .ant-layout-header &,
    .ant-popover-content & {
      border: 0 none;
    }

    &:focus {
      box-shadow: none;
      background-color: fade(@grey-2, 30%);
    }
  }

  & .@{class-prefix}-search-icon {
    background-color: transparent;
    border: 0 none;
    color: lighten(@grey-6, 10%);
    height: 32px;
    width: 30px;
    line-height: 36px;
    text-align: center;

    position: absolute;
    right: 0;
    top: 0;

    .@{class-prefix}-algolia-header & {
      margin-top: 2px;
      text-align: left;
    }
  }

  & .ant-input-lg + .@{class-prefix}-search-icon {
    height: 40px;
    line-height: 45px;
  }
}

.@{class-prefix}-lt-icon-search-bar {
  & input[type='search'] {
    padding-right: 16px;
    padding-left: 35px;
    border: 0 none;
  }

  & .@{class-prefix}-search-icon {
    left: 0;
    right: auto;
  }

  .@{class-prefix}-algolia-header & {
    margin-right: 10px;

    @media screen and (max-width: @screen-lg-max) {
      width: 140px;
    }

    @media screen and (max-width: (@screen-xs-max - 175px)) {
      margin-right: 0;
      margin-bottom: 10px;
      width: auto;
    }
  }
}

.@{class-prefix}-lt-icon-search-bar-lg {
  width: 350px;

  & input[type='search'] {
    .border-radius(@border-radius-sm);
    height: 46px;
    padding: 10px 18px 10px 40px;

    @media screen and (max-width: @screen-md-max) {
      height: 38px;
      padding: 5px 18px 5px 40px;
    }

    .@{class-prefix}-header-search & {
      background-color: darken(@grey-2, 2%);
      height: 36px;
      .border-radius(@border-radius-sm 0 0 @border-radius-sm);
      border: @border-style-base @border-width-base @border-color;

      &:focus {
        background-color: darken(@grey-2, 3%);
      }

      .@{class-prefix}-header-horizontal-dark & {
        border-color: fade(@white-color, 20%);
        background-color: fade(@white-color, 20%);
        color: @white-color;

        &:focus {
          background-color: transparent;
        }
      }
    }
  }

  & .@{class-prefix}-search-icon {
    width: 35px;
    height: 46px;
    line-height: 50px;
    font-size: 16px;
    left: 0;
    right: auto;

    @media screen and (max-width: @screen-md-max) {
      height: 38px;
      line-height: 40px;
    }

    .@{class-prefix}-header-search & {
      height: 36px;
      line-height: 40px;
    }
  }

  .@{class-prefix}-inside-header-horizontal & {
    width: 200px;
    margin-right: 10px;

    @media screen and (max-width: @screen-lg-max) {
      width: 120px;
    }
  }

  @media screen and (max-width: @screen-lg-max) {
    width: 150px;
  }
}

.@{class-prefix}-popover-search-bar {
  width: 150px;
  margin: -10px -14px;
}

.@{class-prefix}-chat-search-bar {
  .flex(1);
  min-width: 100px;
  width: auto;

  & input[type='search'] {
    .box-shadow(none);
    .border-radius(0);
    height: 40px;
    border: 0 none;

    &:focus {
      background-color: @white-color;
    }
  }

  & .@{class-prefix}-search-icon {
    height: 40px;
    line-height: 44px;
  }
}

.ant-transfer-list {
  width: 150px;

  @media screen and (max-width: @screen-xs-max) {
    width: 100%;
  }
}

.@{class-prefix}-select-sm {
  font-size: @font-size-sm;

  & .ant-select-selection {
    .border-radius(@border-radius-sm);
  }

  & .ant-select-selection--single,
  &.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: @input-height-sm;
    padding: 0 7px;
  }

  &.ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
    height: @input-height-sm - 2;
  }

  &.ant-select-single .ant-select-selector .ant-select-selection-item,
  &.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    line-height: @input-height-sm - 2;
  }

  & .ant-select-selection__rendered {
    line-height: @input-height-sm - 2px;
    margin-left: 6px;
    margin-right: 6px;
  }

  & .ant-select-selection-selected-value {
    padding-right: 20px;
  }

  & .ant-select-arrow {
    right: 6px;
  }

  & .ant-select-dropdown {
    font-size: @font-size-sm;
  }
}

.@{class-prefix}-form-inline-label-up {
  & .ant-form-item,
  & .ant-form-item > .ant-form-item-control-wrapper,
  & .ant-form-item > .ant-form-item-label {
    display: block;
  }

  & .ant-form-item-label {
    text-align: left;
    color: @grey-6;
    line-height: 1;
    margin-bottom: 4px;
  }

  & .ant-select-selection--single,
  & .ant-input {
    height: @input-height-base-sm + 2px;
  }

  & .ant-select-selection__rendered {
    line-height: @input-height-base-sm;
  }

  & .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: 30px;
  }

  & .ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
    height: 28px;
  }

  & .ant-select-single .ant-select-selector .ant-select-selection-item,
  & .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    line-height: 28px;
  }

  &.ant-form-inline .ant-form-item > .ant-form-item-label,
  &.ant-form-inline .ant-form-item > .ant-form-item-control {
    display: block;
  }

  & .ant-form-item-label > label {
    height: auto;
  }
}

.@{class-prefix}-input-lineheight {
  line-height: 36px;
}

.ant-mention-wrapper {
  border: @border-style-base @border-width-base @border-color;
  padding: 8.6px 11px;
  .border-radius(@border-radius-base);
}

@media screen and (max-width: @screen-xs-max) {
  .@{class-prefix}-ant-transfer-width .ant-transfer-list {
    width: 100% !important;
  }
}

@media screen and (max-width: (@screen-xs-max - 100px)) {
  .ant-input-group.ant-input-group-compact {
    & > * {
      .border-radius(4px) !important;
      width: 100% !important;
      border-right-width: 1px;

      &:not(:last-child) {
        margin-bottom: 10px;
      }
    }

    // reset border for Select, DatePicker, AutoComplete, Cascader, Mention, TimePicker
    & > .@{ant-prefix}-select > .@{ant-prefix}-select-selection,
    & > .@{ant-prefix}-calendar-picker .@{ant-prefix}-input,
    & > .@{ant-prefix}-select-auto-complete .@{ant-prefix}-input,
    & > .@{ant-prefix}-cascader-picker .@{ant-prefix}-input,
    & > .@{ant-prefix}-mention-wrapper .@{ant-prefix}-mention-editor,
    & > .@{ant-prefix}-time-picker .@{ant-prefix}-time-picker-input {
      border-radius: 4px;
      border-right-width: 1px;
    }

    & .@{class-prefix}-border-lt-xs {
      border-left: @border-width-base @border-style-base @input-border-color !important;
    }
  }
}
