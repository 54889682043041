@import '../global/variables.less';

/* Steps Styles */
.ant-steps {
  & .ant-steps-item {
    &.ant-steps-item-active {
      & .ant-steps-item-content {
        & .ant-steps-item-title {
          color: @primary-color;
          font-weight: 600;
          font-size: 1.2rem;
        }
      }
    }
  }
}

.ant-steps-dot {
  flex-wrap: wrap;
  overflow: hidden;
  padding: 10px 0;

  & .ant-steps-item {
    margin-bottom: 10px;
  }

  & .ant-steps-item-description {
    @media screen and (max-width: @screen-xs-max) {
      display: none;
    }
  }
}
